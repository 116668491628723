import { FC } from 'react';

import styled from '@emotion/styled';

import { Icon } from '../../components/Icon';
import { Text } from '../../components/Text';
import { capitalizeWords } from '../../utils/fp';

type OptionProps = {
	label: string;
	value: any;
	selectHasIcon?: boolean;
	isSelected: boolean;
};

const Container = styled('div')<{ selectHasIcon?: boolean }>`
	padding: 5px 0px 3px;
	border-radius: 4px;
	outline: none;
	font-weight: normal;
	transition: background ${({ theme }) => theme.transition.baseTransition};
	cursor: pointer;
`;

const StyledIcon = styled(Icon)`
	width: 15px;

	svg {
		width: 15px;
	}
`;

const OptionWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
`;

const AirportInfoWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: 17px;
`;

const UpperlineWrapper = styled('div')`
	display: flex;
	flex-direction: row;
`;

export const AirportsMenuOption: FC<OptionProps> = ({
	label,
	value,
	selectHasIcon,
	isSelected,
}) => (
	<Container selectHasIcon={selectHasIcon} data-testid="multi-select-option" data-testvalue={label}>
		<OptionWrapper>
			<StyledIcon type={isSelected ? 'check-primary' : 'aircraft-filled-gray'} />
			<AirportInfoWrapper>
				<UpperlineWrapper>
					<Text gray medium>
						{capitalizeWords(value?.name)}
					</Text>
					<Text grayDark medium>
						&nbsp;{value?.airportCode}
					</Text>
				</UpperlineWrapper>
				<Text gray subtext medium>
					{capitalizeWords(value?.city)}, {value?.state?.code}
				</Text>
			</AirportInfoWrapper>
		</OptionWrapper>
	</Container>
);
