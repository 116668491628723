import { GqlBusinessCaseDetailAirport } from '../gqlTypes';
import { Airport } from '../types';
import { capitalizeWords } from './fp';

export const toString = (value) => (value !== '' && value != null ? value.toString() : value);

export const toInt = (value) => parseInt(value, 10) || null;

export const formatPrice = (
	price: string | number,
	toFixed: number = 2,
	noPrefix: boolean = false,
) => {
	if (price === undefined || isNaN(Number(price))) {
		return '-';
	}

	const digitFollowedBy3digitsRegexp = /(\d)(?=(?:\d\d\d)+(?!\d))/g;

	const prefixUSD = noPrefix ? '' : '$';
	return `${prefixUSD}${Number(price)
		.toFixed(toFixed)
		.toString()
		.replace(digitFollowedBy3digitsRegexp, '$1,')}`;
};

export const formatAirportName = (airport: Airport | GqlBusinessCaseDetailAirport) => {
	return `${airport?.city} - ${airport?.airportCode} - ${capitalizeWords(
		airport?.name?.toLowerCase(),
	)}`;
};

export const getSavedPrice = (
	totalPrice: number,
	totalSeats: number,
	occupiedSeats: number,
	reservedSeats: number,
) => {
	const partOfWholePrice = (totalPrice / occupiedSeats) * reservedSeats;
	const potentialPartOfWholePrice = (totalPrice / totalSeats) * reservedSeats;

	return partOfWholePrice - potentialPartOfWholePrice;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
	const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return `${match[1]} ${match[2]} ${match[3]}`;
	}
	return cleaned;
};
