import { useAuthentication } from '@fllite-fe/shared';
import { UserBox } from '@fllite-fe/shared/src/components/UserBox';

import { menuItems } from './menuItems';
import { NavigationLinkList } from './Navigation.style';
import { NavigationLink } from './NavigationLink';

export const Desktop = () => {
	const { user } = useAuthentication();

	return (
		<>
			{user?.confirmed && (
				<NavigationLinkList>
					{menuItems.map((menuItem) => (
						<NavigationLink href={menuItem.href} dataTestid={menuItem.dataTestid} key={menuItem.id}>
							{menuItem.title}
						</NavigationLink>
					))}
				</NavigationLinkList>
			)}

			<UserBox />
		</>
	);
};
