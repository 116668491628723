import React, { useState } from 'react';

import { useApiAirportsGetCollection } from '@fllite-fe/api';

import { useDebounce } from '../hooks/useDebounce';
import { Airport } from '../types';
import { formatAirportName } from './formatters';

export const useMultiSelectPropsWithAirportLoad = (): Partial<any> => {
	const [query, setQuery] = useState<string>(null);
	const debouncedQuery = useDebounce(query, 400);

	const { isLoading, data } = useApiAirportsGetCollection<Airport[]>(
		{ query: debouncedQuery },
		{
			query: {
				enabled: !!debouncedQuery && !!query,
				retry: false,
				queryKey: ['airports'],
			},
		},
	);

	const onChange = (evt: React.SyntheticEvent, search: string) => {
		// Debounce this.
		setQuery(search);
	};

	return {
		onInputChange: onChange,
		loading: isLoading && !!query,
		options: (data ?? []).map((item) => ({
			label: formatAirportName(item),
			value: {
				id: item.id,
				name: item.name,
				airportCode: item.airportCode,
				city: item.city,
				state: { code: item.state?.code },
				timeZone: item.timeZone,
				latitude: item.latitude,
				longitude: item.longitude,
			},
		})),
	};
};
