import styled from '@emotion/styled';
import { Anchor } from 'components/Navigation/NavigationLink';

import { Icon } from '@fllite-fe/shared/src/components/Icon';

export const FOOTER_HEIGHT_MEDIUM_UP = 162;
export const FOOTER_HEIGHT_MEDIUM_DOWN = 416;

export const Container = styled('div')`
	background-color: #fff;
	position: relative;
	height: ${FOOTER_HEIGHT_MEDIUM_DOWN}px;

	${({ theme }) => theme.breakpoints.up('md')} {
		height: ${FOOTER_HEIGHT_MEDIUM_UP}px;
	}
`;

export const Content = styled('div')`
	max-width: ${({ theme }) => theme.dimensions.pageWidth};
	margin: 0 auto;
	padding: 32px 20px 26px;
`;

export const TopContent = styled('div')`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	${({ theme }) => theme.breakpoints.up('md')} {
		justify-content: space-between;
		flex-direction: row;
	}
`;

export const BottomContent = styled('div')`
	text-align: center;
	opacity: 0.75;
	margin-top: 25px;

	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		flex-direction: column;
	}
`;

export const Separator = styled('div')`
	width: 100%;
	height: 1px;
	background-color: ${({ theme }) => theme.colors.primaryMedium};
	opacity: 0.25;
`;

export const FlliteLogo = styled(Icon)`
	flex: 1;

	svg {
		width: 103px;
	}
`;

export const Navigation = styled('div')`
	display: flex;
	flex-direction: column;
	margin-top: 20px;

	${Anchor} {
		margin-right: 30px;

		${({ theme }) => theme.breakpoints.down('md')} {
			margin-bottom: 16px;
		}
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		justify-content: flex-start;
		flex-direction: row;
		margin-top: 0;
		width: 100%;
		margin-left: 69px;
	}
`;

export const Logos = styled('div')`
	display: flex;
	flex-direction: column;
`;

export const NBAA = styled('div')`
	flex: 1;
	margin-top: 20px;
	flex-grow: 0;

	${({ theme }) => theme.breakpoints.up('md')} {
		text-align: right;
		margin-top: -10px;
	}
`;

export const PaymentMethods = styled('div')`
	width: 254px;
	height: 32px;
	margin: 16px 0;
`;

export const UpperLine = styled('div')`
	display: flex;
	flex-direction: column;
	text-align: left;

	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`;

export const CopyWrapper = styled('div')`
	margin-top: 10px;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 0px;
	}
`;

export const NBAALogo = styled(Icon)`
	flex-grow: 0;

	svg {
		width: 127px;
	}
`;
