export const menuItems = [
	{
		id: 1,
		title: 'Dashboard',
		href: '/',
		dataTestid: 'navbarDashboard',
	},
	{
		id: 2,
		title: 'My Trips',
		href: '/my-flights',
		dataTestid: 'navbarMyFlights',
	},
	{
		id: 3,
		title: 'Grab a Seat',
		href: '/jump-on-a-flight',
		dataTestid: 'navbarGrabASeat',
	},
	{
		id: 5,
		title: 'FAQs',
		href: '/faq',
		dataTestid: 'navbarFaqs',
	},
];

export const mobileExtendedMenuItems = [
	{
		id: 5,
		title: 'Contact us',
		href: `/contact-us`,
		dataTestid: 'navigation-link-contact',
	},
	{
		id: 6,
		title: 'Privacy Policy',
		href: '/privacy',
		dataTestid: 'footer-privacy',
	},
	{
		id: 7,
		title: 'Terms & Conditions',
		href: '/terms',
		dataTestid: 'footer-terms',
	},
];
