const capitalize = (s) => {
	if (typeof s !== 'string') {
		return '';
	}

	return s.toLowerCase().replace(/(?:^|[\s-/])\w/g, (match) => match.toUpperCase());
};

export const capitalizeWords = (s) => {
	if (typeof s !== 'string') {
		return '';
	}

	return s.split(' ').map(capitalize).join(' ');
};
