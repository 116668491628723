import { FC } from 'react';
import * as React from 'react';

import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styledMap from 'styled-map';

import { Text } from '@fllite-fe/shared/src/components/Text';

const anchorBackground = styledMap`
  default: transparent;
  featured: ${({ theme }) => theme.colors.primary};
`;

const anchorBackgroundHover = styledMap`
  default: transparent;
  featured: ${({ theme }) => theme.colors.primaryDark};
`;

const anchorBorderRadius = styledMap`
  default: initial;
  featured: ${({ theme }) => theme.radius.buttonRadius};
`;

const anchorPadding = styledMap`
  default: 0;
  featured: 5px 20px 2px;
`;

interface NavigationLinkProps {
	featured?: boolean;
	href: string;
	children: React.ReactNode;
	hideOnDesktop?: boolean;
	dataTestid?: string;
	gray?: boolean;
	popup?: boolean;
	primaryMedium?: boolean;
	medium?: boolean;
	useActive?: boolean;
}

type AnchorProps = Pick<NavigationLinkProps, 'featured' | 'popup' | 'hideOnDesktop'> & {
	active?: boolean;
	useActive?: boolean;
	primaryMedium?: boolean;
};

export const Anchor = styled('a')<AnchorProps>`
	margin: 0 8px;
	padding: ${anchorPadding};
	cursor: pointer;
	background-color: ${anchorBackground};
	border-radius: ${anchorBorderRadius};
	transition: background-color ${({ theme }) => theme.transition.baseTransition};
	position: relative;

	:hover {
		background-color: ${anchorBackgroundHover};
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		display: ${({ hideOnDesktop }) => hideOnDesktop && 'none'};
		visibility: ${({ hideOnDesktop }) => hideOnDesktop && 'hidden'};

		::after {
			content: '';
			display: ${({ active, useActive }) =>
				active ? 'block' : useActive === false ? 'block' : 'none'};
			position: absolute;
			bottom: ${({ popup }) => (popup ? '-8px' : '-22px')};
			height: 3px;
			left: ${({ popup }) => (popup ? '-2px' : '-10px')};
			right: -10px;
			background: ${({ theme }) => theme.colors.warning};
		}
	}

	${Text} {
		color: ${({ active, popup, theme, useActive }) =>
			active && useActive && (popup ? theme.colors.grayDarker : theme.colors.baseFontColor)};
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		${Text} {
			color: ${({ theme, popup, primaryMedium }) =>
				primaryMedium
					? theme.colors.primaryMedium
					: popup
						? theme.colors.grayDarker
						: theme.colors.baseFontColor};
		}
	}
`;

export const NavigationLink: FC<NavigationLinkProps> = ({
	featured,
	href,
	children,
	hideOnDesktop,
	dataTestid,
	gray,
	popup,
	primaryMedium,
	medium = false,
	useActive = true,
}) => {
	const { pathname } = useRouter();

	return (
		<Link href={href} passHref legacyBehavior>
			<Anchor
				popup={popup}
				featured={featured}
				hideOnDesktop={hideOnDesktop}
				data-testid={dataTestid}
				active={useActive && pathname === href}
				primaryMedium={primaryMedium}
			>
				<Text gray={gray} medium={medium} primaryMedium={primaryMedium}>
					{children}
				</Text>
			</Anchor>
		</Link>
	);
};
