import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

import { Icon } from '@fllite-fe/shared/src/components/Icon';
import { Text } from '@fllite-fe/shared/src/components/Text';

import { Anchor } from './NavigationLink';

interface NavigationProps {
	isOpen: boolean;
}

export const NAVBAR_HEIGHT_MEDIUM_UP = 70;
export const NAVBAR_HEIGHT_MEDIUM_DOWN = 56;

export const Container = styled('div')`
	padding: 8px 20px;
	position: sticky;
	right: 0;
	top: 0;
	left: 0;
	background: ${({ theme }) => theme.backgrounds.secondaryGradient};
	z-index: 16;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 10px 20px;
	}
`;

export const LeftSideWrap = styled('div')`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
export const RightSideWrap = styled('div')`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const Content = styled('div')`
	max-width: ${({ theme }) => theme.dimensions.pageWidthLarge};
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

interface NavigationLinkProps {
	isMobile?: boolean;
}
export const NavigationLinkList = styled('div')<NavigationLinkProps>`
	display: flex;
	flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
	${Anchor} {
		display: flex;
		margin-left: 30px;
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
	}
`;

export const DesktopNavigation = styled('div')`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-direction: column;
		padding-top: 50px;

		${Anchor} {
			display: flex;
			margin-left: 0;
			margin-bottom: 20px;
		}
	}
`;
export const MobileNavigation = styled('div')`
	display: none;

	${({ theme }) => theme.breakpoints.down('md')} {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: column;
		padding-top: 20px;

		${Anchor} {
			display: flex;
			margin-left: 0;
			margin-bottom: 20px;
		}
	}
`;

export const NavigationWrap = styled('div')<NavigationProps>`
	height: 100%;
	width: 100%;

	${({ theme }) => theme.breakpoints.down('md')} {
		display: flex;
		flex-direction: column;
		z-index: 20;
		position: fixed;
		max-width: 260px;
		right: 0;
		transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
		top: 0;
		background: ${({ theme }) => theme.backgrounds.primaryGradient};
		transition: transform 0.2s cubic-bezier(0, 0, 0, 1);
		padding: 40px 20px;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		justify-content: space-between;
	}
`;

export const Line = styled('div')`
	width: 22px;
	height: 2px;
	border-radius: 12px;
	margin: 4px 0;
	transition: 0.4s;
`;

export const Burger = styled('div')<NavigationProps>`
	display: inline-block;
	cursor: pointer;
	position: ${({ isOpen }) => (isOpen ? 'fixed' : 'relative')};
	right: ${({ isOpen }) => (isOpen ? '20px' : 'auto')};
	top: ${({ isOpen }) => (isOpen ? '20px' : 'auto')};
	z-index: 30;
	padding: 10px;
	max-height: 40px;

	${({ theme }) => theme.breakpoints.up('md')} {
		display: none;
	}

	${Line} {
		background-color: ${({ theme, isOpen }) => (isOpen ? theme.colors.primary : '#fff')};

		&:first-of-type {
			transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg) translate(-3px, 3px)' : 'none')};
		}

		&:nth-child(2) {
			opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
		}

		&:last-child {
			transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg) translate(-6px, -6px)' : 'none')};
		}
	}
`;

export const Logo = styled(Icon)`
	width: 82px;
	height: 20px;
	margin-right: 24px;
	display: inline-flex;
	align-items: center;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: 130px;
		margin-right: 30px;
	}

	svg {
		width: 130px;
	}
`;

export const LogoWrapper = styled('a')`
	display: inline-flex;
`;

export const HomebaseWrap = styled('div')`
	display: flex;
	align-items: center;

	${({ theme }) => theme.breakpoints.down('md')} {
		margin-top: 20px;
	}
`;

export const StyledForm = styled('form')`
	width: 230px;
`;

export const UserMenu = styled('div')`
	background: ${({ theme }) => theme.colors.primaryDarkest};
	border-radius: 51px;
	display: flex;
	align-items: center;
	padding: 4px 4px 4px 20px;
	margin-left: 30px;
	cursor: pointer;

	${({ theme }) => theme.breakpoints.down('md')} {
		margin-left: 0;
		${Text} {
			color: #fff;
		}
	}
`;

export const UserMenuContent = styled('div')``;

export const UserMenuItem = styled('div')`
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const AvatarWrapper = styled('div')`
	margin-left: 10px;

	img {
		object-fit: cover;
	}
`;

export const UserName = styled(Text)`
	font-size: 14px;
	${({ theme }) => theme.breakpoints.up('sm')} {
		font-size: 16px;
	}
`;

export const StyledPopup = styled(Tooltip)`
	&&&& {
		border-radius: ${({ theme }) => theme.radius.boxRadius};
		padding: 15px 20px;
		min-width: 230px;
		box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
		border: 0;
		margin-top: 15px;

		&:before {
			box-shadow: none;
			width: 16px;
			height: 16px;
			top: -6px;
			right: 16px;
		}
	}
`;

export const TextWithPointer = styled(Text)`
	cursor: pointer;
`;
