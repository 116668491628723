import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Footer } from 'components/Footer';
import { FOOTER_HEIGHT_MEDIUM_UP } from 'components/Footer/Footer.styles';
import { Navigation } from 'components/Navigation';

type UnconfirmedBackgroundType = 'trip' | 'no-trip';
type BackgroundStyle = 'gray' | 'pilot';

const NAVIGATION_HEIGHT = 70;

interface PageProps {
	hasNavigation?: boolean;
	hasFooter?: boolean;
	unconfirmedBackgroundType?: UnconfirmedBackgroundType;
	expired?: boolean;
	backgroundStyle?: BackgroundStyle;
}

const Container = styled('div')``;

const Content = styled('div')<PageProps>`
	min-height: ${({ hasFooter, hasNavigation }) =>
		`calc(100vh - ${hasFooter ? FOOTER_HEIGHT_MEDIUM_UP : 0}px - ${
			hasNavigation ? NAVIGATION_HEIGHT : 0
		}px)`};

	overflow: hidden;

	background: ${({ backgroundStyle, theme }) =>
		backgroundStyle === 'gray'
			? theme.colors.grayLighter
			: backgroundStyle === 'pilot'
				? "transparent url('/assets/dashboard/background.webp') no-repeat top center"
				: '#fff'};
	background-size: contain;

	${({ theme }) => theme.breakpoints.up('xl')} {
		${({ backgroundStyle }) =>
			backgroundStyle === 'pilot' &&
			`background-image: url('/assets/dashboard/background-slim.webp');`}
	}

	${({ unconfirmedBackgroundType, expired }) =>
		unconfirmedBackgroundType
			? css`
					background-image: ${unconfirmedBackgroundType === 'trip'
						? 'url(/assets/trip_bg_1440px.webp)'
						: expired
							? 'url(/assets/header_bg.webp)'
							: 'url(/assets/unconfirmed_bg.webp)'};
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				`
			: ''}
`;

const ContentInner = styled('div')<PageProps>`
	${({ backgroundStyle, unconfirmedBackgroundType }) =>
		backgroundStyle === 'pilot' &&
		!unconfirmedBackgroundType &&
		css`
			background: linear-gradient(to bottom, transparent 15vw, #edf0f4 34vw);
		`}

	${({ theme }) => theme.breakpoints.up('xl')} {
		${({ backgroundStyle, unconfirmedBackgroundType }) =>
			backgroundStyle === 'pilot' &&
			!unconfirmedBackgroundType &&
			css`
				background: linear-gradient(to bottom, transparent 15vw, #edf0f4 26vw);
			`}
	}

	${({ unconfirmedBackgroundType, theme }) =>
		unconfirmedBackgroundType
			? css`
					${theme.breakpoints.up('md')} {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				`
			: ''}
`;

interface Props {
	hasNavigation?: boolean;
	hasFooter?: boolean;
	unconfirmedBackgroundType?: UnconfirmedBackgroundType;
	children: ReactNode;
	expired?: boolean;
	backgroundStyle?: 'gray' | 'pilot';
}

export const Page: FC<Props> = ({
	children,
	hasNavigation = true,
	hasFooter = false,
	unconfirmedBackgroundType = undefined,
	expired = false,
	backgroundStyle,
}) => (
	<Container>
		{hasNavigation && <Navigation />}
		<Content
			hasNavigation={hasNavigation}
			hasFooter={hasFooter}
			backgroundStyle={backgroundStyle}
			unconfirmedBackgroundType={unconfirmedBackgroundType}
			expired={expired}
		>
			<ContentInner
				backgroundStyle={backgroundStyle}
				unconfirmedBackgroundType={unconfirmedBackgroundType}
			>
				{children}
			</ContentInner>
		</Content>
		{hasFooter && <Footer />}
	</Container>
);
