import { useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Paper } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormContainer } from 'react-hook-form-mui';
import * as yup from 'yup';

import { useAuthentication, useFlashMessage } from '@fllite-fe/shared';
import { AirportsMenuOption } from '@fllite-fe/shared/src/mui-form-fields/AutocompleteElement/AirportsMenuOption';
import { capitalizeWords } from '@fllite-fe/shared/src/utils/fp';
import { useMultiSelectPropsWithAirportLoad } from '@fllite-fe/shared/src/utils/loadAirportOptions';

import HomeBaseSelect from './HomeBaseSelect';

type FormValues = {
	homebaseAirport: {
		label: string;
		value: {
			id: number;
			name: string;
			airportCode: string;
			city: string;
			state: { code: string };
		};
	};
};

const schema = yup.object().shape({
	homebaseAirport: yup.mixed(),
});

const CustomPaper = ({ homebaseAirport, ...others }) => {
	return (
		<Paper {...others} sx={{ width: '400px', borderRadius: '5px', marginTop: '5px' }}>
			{homebaseAirport && (
				<Box sx={{ borderBottom: '1px solid rgb(220, 227, 234)', padding: '10px 17px' }}>
					<AirportsMenuOption {...homebaseAirport} selectHasIcon={true} isSelected={true} />
				</Box>
			)}
			{others.children}
		</Paper>
	);
};

export const HomeBaseForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { user, updateMyProfile } = useAuthentication();

	const { pushFlashMessage, FlashMessageTypes } = useFlashMessage();
	const multiSelectProps = useMultiSelectPropsWithAirportLoad();

	const initialValues = useMemo(
		() => ({
			homebaseAirport: user?.homebaseAirport && {
				label: capitalizeWords(user.homebaseAirport.name),
				value: {
					id: user.homebaseAirport.id,
					name: user.homebaseAirport.name,
					airportCode: user.homebaseAirport.airportCode,
					city: user.homebaseAirport.city,
					state: { code: user.homebaseAirport.state?.code },
				},
			},
		}),
		[user],
	);

	const handleFormSubmit = async (values: FormValues) => {
		try {
			setIsLoading(true);
			const airportId = values?.homebaseAirport.value.id;
			const parsedValues = {
				email: user.email,
				firstName: user.firstName,
				lastName: user.lastName,
				phone: user.phone,
				homebaseAirport: airportId ? { id: airportId } : null,
			};
			updateMyProfile({ id: user.id, data: parsedValues });
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			// @TODO: Flash should be part of updateMyProfile
			pushFlashMessage({
				type: FlashMessageTypes.Error,
				text: 'An error occurred while updating your homebase.',
			});
		}
	};

	const formContext = useForm({
		resolver: yupResolver(schema),
		values: initialValues,
		resetOptions: {
			keepDirtyValues: true,
		},
	});
	const homebaseAirport = formContext.watch('homebaseAirport');

	const options = useMemo(
		() =>
			multiSelectProps.options?.length === 1 &&
			multiSelectProps.options[0].value?.id === homebaseAirport?.value?.id
				? []
				: multiSelectProps.options,
		[multiSelectProps.options],
	);

	return (
		<FormContainer
			formContext={formContext}
			onSuccess={handleFormSubmit}
			FormProps={{
				style: {
					width: '230px',
				},
			}}
		>
			<HomeBaseSelect
				isLoading={isLoading}
				name="homebaseAirport"
				icon="location-blue-light-small"
				textFieldProps={{
					placeholder: 'Homebase airport',
				}}
				options={options}
				loading={multiSelectProps.loading}
				autocompleteProps={{
					onInputChange: multiSelectProps.onInputChange,
					blurOnSelect: true,
					autoSelect: true,
					openOnFocus: true,
					PaperComponent: (props) => <CustomPaper homebaseAirport={homebaseAirport} {...props} />,
					noOptionsText: 'Please start typing to find airports',
				}}
				customOnChange={handleFormSubmit}
			/>
		</FormContainer>
	);
};
