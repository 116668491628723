import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Link from 'next/link';

import { useAuthentication } from '@fllite-fe/shared';

import { Desktop } from './Desktop';
import { HomeBaseForm } from './HomeBaseForm';
import { Mobile } from './Mobile';
import {
	Container,
	Content,
	LeftSideWrap,
	Logo,
	LogoWrapper,
	RightSideWrap,
} from './Navigation.style';

export const Navigation = () => {
	const theme = useTheme() as Theme;
	const isDesktopMatch = useMediaQuery(theme.breakpoints.up('md'));
	const { user } = useAuthentication();

	return (
		<Container>
			<Content>
				<LeftSideWrap>
					<Link passHref href="/" legacyBehavior>
						<LogoWrapper>
							<Logo type={isDesktopMatch ? 'logo-white' : 'logo-white-mobile'} />
						</LogoWrapper>
					</Link>
					{isDesktopMatch && user?.confirmed && <HomeBaseForm />}
				</LeftSideWrap>
				<RightSideWrap>{isDesktopMatch ? <Desktop /> : <Mobile />}</RightSideWrap>
			</Content>
		</Container>
	);
};
